import React, { ReactNode } from 'react';
import {
  SortableContainer,
  SortableElement,
  SortEnd,
  SortEndHandler
} from 'react-sortable-hoc';
import {
  RiCameraFill,
  RiCameraOffFill,
  RiChat4Fill,
  RiChatOffFill,
  RiCheckLine,
  RiDeleteBin6Fill,
  RiDownload2Line,
  RiEyeFill,
  RiEyeOffFill,
  RiFolderZipFill,
  RiFolderZipLine,
  RiHammerFill,
  RiIndeterminateCircleFill
} from 'react-icons/ri';
import {
  BulkActionsDropdown,
  Button,
  Flex,
  ConfirmModal,
  Box,
  Grid,
  RiFilterOffLine
} from '@itandi/itandi-bb-ui';
import Styles from './ReportImagesArea.module.scss';
import {
  DealReportBulkUpdateParams,
  DealReportImage,
  ReportImageBulkAction
} from '~/models/DealReportImage';
import { ReportImagesAreaBlock } from '~/components/common/reports/ReportImagesAreaBlock';
import { NskResponse } from '../../../models/Api';
import { useOpenHandler } from '~/hooks/useOpenHandler';

type Props = {
  reportImages: DealReportImage[];
  handleDownloadImage: (reportImage: DealReportImage) => void;
  handleMutateReportImages: (() => Promise<void>) | null;
  handleUpdateSeq?:
    | ((newOrderImages: DealReportImage[]) => Promise<void>)
    | null;
  handleUpdate: (submitValue: DealReportImage) => Promise<NskResponse<void>>;
  handleDestroy?:
    | ((reportImage: DealReportImage) => Promise<NskResponse<void>>)
    | null;
  handleDownloadPdf: () => void;
  handleDownloadZip?: () => void;
  companyType: 'management' | 'own_management' | 'vendor';
  onChangeSelectedImage: (reportImageId: number) => void;
  selectedImageIds: ReadonlyArray<number>;
  handleSelectedDownloadZip?: () => void;
  handleBulkUpdate: (submitValue: DealReportBulkUpdateParams) => Promise<void>;
  handleBulkDelete: (() => void) | null;
  handleResetSelectedIds: () => void;
  handleSelectAllImages: () => void;
};

type SortableContainerProps = {
  items: DealReportImage[];
};

export const ReportImagesArea: React.FC<Props> = ({
  reportImages,
  handleDownloadImage,
  handleMutateReportImages,
  handleUpdateSeq = null,
  handleUpdate,
  handleDestroy = null,
  handleDownloadPdf,
  handleDownloadZip,
  companyType,
  onChangeSelectedImage,
  selectedImageIds,
  handleSelectedDownloadZip,
  handleBulkUpdate,
  handleBulkDelete,
  handleResetSelectedIds,
  handleSelectAllImages
}: Props) => {
  // 並び替えのためのstate
  const [orderImages, setOrderImages] = React.useState<DealReportImage[]>([]);
  const isVendor = companyType === 'vendor';
  const {
    isOpen: isOpenBulkDelete,
    handleOpen: handleOpenBulkDelete,
    handleClose: handleCloseBulkDelete
  } = useOpenHandler();
  React.useEffect(() => {
    setOrderImages(reportImages);
  }, [reportImages]);
  let tmpSeqNo = 0;
  const onSortEnd: SortEndHandler = ({ oldIndex, newIndex }: SortEnd) => {
    tmpSeqNo = 0;
    if (oldIndex === newIndex) {
      return;
    }
    const moveImage = orderImages[oldIndex];
    const newOrderItems: DealReportImage[] = [];
    const wkOrderImages = [...orderImages];
    wkOrderImages.splice(oldIndex, 1);
    wkOrderImages.splice(newIndex, 0, moveImage);
    wkOrderImages.forEach((r: DealReportImage, i: number) => {
      newOrderItems.push({ ...r, seq: i + 1 });
    });
    setOrderImages(newOrderItems);
    handleUpdateSeq && handleUpdateSeq(newOrderItems).then();
  };
  const renderImageBlock = (
    reportImage: DealReportImage,
    index: number
  ): ReactNode => {
    const displaySeqNo = ((): number | null => {
      if (isVendor) {
        return index + 1;
      }
      if (reportImage.outputPdfImage) {
        tmpSeqNo += 1;
        return tmpSeqNo;
      }
      return null;
    })();
    const SortableItem = SortableElement(() => (
      <ReportImagesAreaBlock
        key={reportImage.id}
        companyType={companyType}
        displaySeqNo={displaySeqNo}
        handleDestroy={handleDestroy}
        handleDownloadImage={handleDownloadImage}
        handleMutateReportImages={handleMutateReportImages}
        handleUpdate={handleUpdate}
        handleUpdateSeq={handleUpdateSeq}
        onChangeSelectedImage={onChangeSelectedImage}
        reportImage={reportImage}
        selectedImageIds={selectedImageIds}
      />
    ));
    return <SortableItem key={reportImage.id} index={index} />;
  };
  const SortableList = SortableContainer<SortableContainerProps>(
    (props: SortableContainerProps) => {
      const { items } = props;
      return (
        <div className={Styles.ReportImageList}>
          {items.map(renderImageBlock)}
        </div>
      );
    }
  );
  const bulkActionList = (): ReportImageBulkAction[] => {
    const actions: ReportImageBulkAction[] = [];
    if (handleSelectedDownloadZip) {
      actions.push({
        children: '画像をダウンロード',
        icon: <RiFolderZipFill />,
        onClick: handleSelectedDownloadZip
      });
    }
    if (isVendor || companyType === 'own_management') {
      actions.push({
        children: '施工推奨にする',
        icon: <RiHammerFill />,
        onClick: () => handleBulkUpdate({ construction: 'targeted' })
      });
      actions.push({
        children: '施工推奨から外す',
        icon: <RiIndeterminateCircleFill />,
        onClick: () => handleBulkUpdate({ construction: 'unspecified' })
      });
    }
    if (isVendor) {
      actions.push({
        children: '写真を共有する',
        icon: <RiEyeOffFill />,
        onClick: () => handleBulkUpdate({ shared: true })
      });
      actions.push({
        children: '写真を共有しない',
        icon: <RiEyeFill />,
        onClick: () => handleBulkUpdate({ shared: false })
      });
    } else {
      actions.push({
        children: '写真を報告書に含める',
        icon: <RiCameraFill />,
        onClick: () => handleBulkUpdate({ outputPdfImage: true })
      });
      actions.push({
        children: '写真を報告書に含めない',
        icon: <RiCameraOffFill />,
        onClick: () => handleBulkUpdate({ outputPdfImage: false })
      });
      actions.push({
        children: '備考を報告書に含める',
        icon: <RiChat4Fill />,
        onClick: () => handleBulkUpdate({ outputPdfNote: true })
      });
      actions.push({
        children: '備考を報告書に含めない',
        icon: <RiChatOffFill />,
        onClick: () => handleBulkUpdate({ outputPdfNote: false })
      });
    }
    if (handleBulkDelete) {
      actions.push({
        children: '画像を削除',
        danger: true,
        icon: <RiDeleteBin6Fill />,
        onClick: handleOpenBulkDelete
      });
    }
    return actions;
  };
  return (
    <div className={Styles.ReportImagesArea}>
      <Grid
        alignItems="center"
        autoFlow="column"
        gap={4}
        justifyContent="space-between"
        marginBottom={8}
      >
        <Box>
          <Flex gap={16} margin="8px 2px">
            <Button
              disabled={orderImages.length <= 0}
              endIcon={<RiDownload2Line />}
              onClick={handleDownloadPdf}
              variant="secondary"
            >
              報告書ダウンロード
            </Button>
            {handleDownloadZip && (
              <Button
                disabled={orderImages.length <= 0}
                endIcon={<RiFolderZipLine />}
                onClick={handleDownloadZip}
                variant="secondary"
              >
                画像一括ダウンロード
              </Button>
            )}
          </Flex>
        </Box>
        <Box>
          <Flex gap={16} margin="8px 2px">
            <Button
              disabled={selectedImageIds.length === 0}
              onClick={handleSelectAllImages}
              startIcon={<RiCheckLine />}
              variant="secondary"
            >
              全てを選択
            </Button>
            <Button
              disabled={selectedImageIds.length === 0}
              onClick={handleResetSelectedIds}
              startIcon={<RiFilterOffLine />}
              variant="secondary"
            >
              選択をクリア
            </Button>
          </Flex>
        </Box>
      </Grid>
      <BulkActionsDropdown
        dropdownLabel="まとめて操作"
        dropdownMenuItems={bulkActionList()}
        selectedCount={selectedImageIds.length}
      >
        <SortableList
          axis="xy"
          items={orderImages}
          onSortEnd={onSortEnd}
          onSortStart={(): void => {
            tmpSeqNo = 0;
          }}
          useDragHandle
        />
      </BulkActionsDropdown>
      {handleBulkDelete && (
        <ConfirmModal
          handleClose={handleCloseBulkDelete}
          handleSubmit={(): void => {
            handleCloseBulkDelete();
            handleBulkDelete();
          }}
          headerTitle="一括削除"
          isOpen={isOpenBulkDelete}
          submitLabel="削除"
          useDefaultFooterCancel
        >
          {`${selectedImageIds.length}件の画像を削除してよろしいですか？`}
        </ConfirmModal>
      )}
    </div>
  );
};
