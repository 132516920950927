import React from 'react';
import { RiUserLine } from 'react-icons/ri';
import {
  Input,
  ItandiChip,
  InputRadio,
  Button,
  Flex
} from '@itandi/itandi-bb-ui';
import { useFormContext } from 'react-hook-form';
import Styles from './Block.module.scss';
import {
  DealParam,
  resetTenantValues,
  setTenantValues
} from '~/models/DealForm';
import { PropertyDealRoom } from '~/models/PropertyDeal';
import useItandiAccount from '~/hooks/useItandiAccount';

type Props = {
  currentRoom?: PropertyDealRoom | null;
};
export const TenantBlock: React.FC<Props> = ({ currentRoom = null }: Props) => {
  const { data: session } = useItandiAccount().currentAccount();
  const { register, formState, watch, setValue, getValues } =
    useFormContext<DealParam>();
  const { errors } = formState;
  const onChangeFile = React.useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      name: 'leaseContractFile1' | 'leaseContractFile2' | 'leaseContractFile3'
    ): void => {
      const { files } = e.target;
      if (files !== null) {
        const uploadFile: File = files[0];
        setValue(name, uploadFile);
      }
    },
    [setValue]
  );
  const onClickFileLink = React.useCallback(
    (
      e: React.MouseEvent,
      name: 'leaseContractFile1' | 'leaseContractFile2' | 'leaseContractFile3'
    ): void => {
      const file = getValues(name);
      if (file !== null) {
        e.preventDefault();
        const url = URL.createObjectURL(file);
        window.open(url);
      }
    },
    [getValues]
  );
  const showTenantFillButton =
    currentRoom !== null &&
    !session?.company?.useBkkLinkage &&
    currentRoom?.tenantName !== '';
  const tenantStatus: 'resident' | 'leaved' = watch('tenantStatus');
  const leasingContractFile1: File | null = watch('leaseContractFile1');
  const leasingContractFile2: File | null = watch('leaseContractFile2');
  const leasingContractFile3: File | null = watch('leaseContractFile3');
  return (
    <div className={Styles.Block}>
      <div className={Styles.BlockCaptionWrapper}>
        <div className={Styles.BlockCaption}>
          <span className={Styles.BlockIconWrapper}>
            <RiUserLine className={Styles.BlockIcon} />
          </span>
          <span className={Styles.BlockCaptionLabel}>入居者情報</span>
        </div>
      </div>
      <div className={Styles.BlockForms}>
        <div className={Styles.FormRow}>
          <div className={Styles.FormContent}>
            <Flex gap={16}>
              {showTenantFillButton && (
                <Button
                  height="small"
                  onClick={(): void => setTenantValues(setValue, currentRoom)}
                  variant="secondary"
                >
                  {`${currentRoom.tenantName}の情報で補完する`}
                </Button>
              )}
              <Button
                height="small"
                onClick={(): void => resetTenantValues(setValue)}
                variant="secondary"
              >
                入居者情報をクリアする
              </Button>
            </Flex>
            {showTenantFillButton && (
              <p className={Styles.Description}>
                補完には直近の案件情報を参照いたします。
              </p>
            )}
          </div>
        </div>
        <div className={Styles.FormRow}>
          <div className={Styles.FormLabel}>
            <span className={Styles.FormLabelText}>立会依頼</span>
          </div>
          <div className={Styles.FormContent}>
            <div className={Styles.InputRadioGroup}>
              <InputRadio
                label="する"
                value="resident"
                {...register('tenantStatus')}
              />
              <InputRadio
                label="しない"
                value="leaved"
                {...register('tenantStatus')}
              />
            </div>
          </div>
        </div>
        <div className={Styles.FormRow}>
          <div className={Styles.FormLabel}>
            <span className={Styles.FormLabelText}>氏名</span>
            <span className={Styles.RequiredLabel}>
              <ItandiChip
                label={tenantStatus !== 'resident' ? '任意' : '必須'}
              />
            </span>
          </div>
          <div className={Styles.FormContent}>
            <div className={Styles.NameKanaGroup}>
              <div className={Styles.NameInputWrapper}>
                <Input
                  isInvalid={errors.tenantName != null}
                  placeholder="例）内装 太郎"
                  {...register('tenantName')}
                />
              </div>
              {errors.tenantName != null && (
                <div className={Styles.ErrorMessage}>
                  {errors.tenantName.message}
                </div>
              )}
              <div className={Styles.NameInputLabel}>カナ</div>
              <div>
                <Input
                  isInvalid={errors.tenantNameKana != null}
                  placeholder="例）ナイソウ タロウ"
                  {...register('tenantNameKana')}
                />
              </div>
              {errors.tenantNameKana != null && (
                <div className={Styles.ErrorMessage}>
                  {errors.tenantNameKana.message}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={Styles.FormRow}>
          <div className={Styles.FormLabel}>
            <span className={Styles.FormLabelText}>電話番号</span>
            <span className={Styles.RequiredLabel}>
              <ItandiChip
                label={tenantStatus !== 'resident' ? '任意' : '必須'}
              />
            </span>
          </div>
          <div className={Styles.FormContent}>
            <div className={Styles.InputWrapper}>
              <Input
                isInvalid={errors.tenantTel != null}
                placeholder="例）090-0000-0000"
                {...register('tenantTel')}
              />
            </div>
            {errors.tenantTel != null && (
              <div className={Styles.ErrorMessage}>
                {errors.tenantTel.message}
              </div>
            )}
          </div>
        </div>
        <div className={Styles.FormRow}>
          <div className={Styles.FormLabel}>
            <span className={Styles.FormLabelText}>メールアドレス</span>
            <span className={Styles.RequiredLabel}>
              <ItandiChip label="任意" />
            </span>
          </div>
          <div className={Styles.FormContent}>
            <div className={Styles.InputWrapper}>
              <Input
                isInvalid={errors.tenantEmail != null}
                placeholder="例）sample@example.com"
                {...register('tenantEmail')}
              />
            </div>
            {errors.tenantEmail != null && (
              <div className={Styles.ErrorMessage}>
                {errors.tenantEmail.message}
              </div>
            )}
          </div>
        </div>
        <div className={Styles.FormRow}>
          <div className={Styles.FormLabel}>
            <span className={Styles.FormLabelText}>賃貸借契約書</span>
            <span className={Styles.RequiredLabel}>
              <ItandiChip label="任意" />
            </span>
          </div>
          <div className={Styles.FormContent}>
            <div className={Styles.InputFileGroup}>
              <div>
                {leasingContractFile1 != null && (
                  <div>
                    <a
                      className={Styles.FileLink}
                      href="#"
                      onClick={(e: React.MouseEvent): void =>
                        onClickFileLink(e, 'leaseContractFile1')
                      }
                      rel="noreferrer"
                      target="_blank"
                    >
                      {leasingContractFile1.name}
                    </a>
                  </div>
                )}
                <div className={Styles.InputWrapper}>
                  <input
                    accept="image/*,application/pdf"
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      onChangeFile(e, 'leaseContractFile1');
                    }}
                    type="file"
                  />
                </div>
                {errors.leaseContractFile1 != null && (
                  <div className={Styles.ErrorMessage}>
                    {errors.leaseContractFile1.message}
                  </div>
                )}
              </div>
              <div>
                {leasingContractFile2 != null && (
                  <div>
                    <a
                      className={Styles.FileLink}
                      href="#"
                      onClick={(e: React.MouseEvent): void =>
                        onClickFileLink(e, 'leaseContractFile2')
                      }
                      rel="noreferrer"
                      target="_blank"
                    >
                      {leasingContractFile2.name}
                    </a>
                  </div>
                )}
                <div className={Styles.InputWrapper}>
                  <input
                    accept="image/*,application/pdf"
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      onChangeFile(e, 'leaseContractFile2');
                    }}
                    type="file"
                  />
                </div>
                {errors.leaseContractFile2 != null && (
                  <div className={Styles.ErrorMessage}>
                    {errors.leaseContractFile2.message}
                  </div>
                )}
              </div>
              <div>
                {leasingContractFile3 != null && (
                  <div>
                    <a
                      className={Styles.FileLink}
                      href="#"
                      onClick={(e: React.MouseEvent): void =>
                        onClickFileLink(e, 'leaseContractFile3')
                      }
                      rel="noreferrer"
                      target="_blank"
                    >
                      {leasingContractFile3.name}
                    </a>
                  </div>
                )}
                <div className={Styles.InputWrapper}>
                  <input
                    accept="image/*,application/pdf"
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      onChangeFile(e, 'leaseContractFile3');
                    }}
                    type="file"
                  />
                </div>
                {errors.leaseContractFile3 != null && (
                  <div className={Styles.ErrorMessage}>
                    {errors.leaseContractFile3.message}
                  </div>
                )}
              </div>
            </div>
            <p className={Styles.Description}>
              ※工事に関する特約がある場合は、賃貸借契約書の添付をお願いします。
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
